<template>
  <b-card
    class="profile-header mb-2"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!--
      :img-src="headerData.image"
     -->
    <div
      class="card-img-top ghost-top-profile-img"
      style="height: 190px;"
      :style="{ background: headerData.slider && headerData.slider.color ? headerData.slider.color : 'linear-gradient(to left, rgb(11, 135, 147), rgb(54, 0, 51))' }"
    >
      <h2
        v-if="headerData.slider && headerData.slider.text"
      >
        {{ headerData.slider.text }}
      </h2>
      <h2
        v-else
      >
        Would you be interested in being a brand influencer on instagram? you can make good money and get free products as well.
      </h2>
    </div>
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-avatar
            :src="headerData.image"
            :text="avatarText(headerData.name)"
            rounded
            fluid
            size="125px"
            alt="profile photo"
          />
        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.name }}
          </h2>
          <p class="text-white">
            @{{ headerData.username }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <!-- active -->
              <b-nav-item
                role="presentation"
                :active="activeSection == 'info'"
                class="font-weight-bold"
                @click="changeSection('info')"
              >
                <span class="d-none d-md-block">Info</span>
                <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="activeSection == 'categories'"
                class="font-weight-bold"
                @click="changeSection('categories')"
              >
                <span class="d-none d-md-block">CRM List</span>
                <feather-icon
                  icon="InfoIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <!-- <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Photos</span>
                <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Friends</span>
                <feather-icon
                  icon="UsersIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item> -->
            </template>

            <!-- edit buttons -->
            <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
                :to="{ name: 'edit-founder', params: { username: headerData.username } }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Edit</span>
              </b-button>
            </template>
            <!-- edit buttons -->
          </b-tabs>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BAvatar,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeSection: 'info'
    }
  },
  watch: {
    '$route.query.section': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) { // && newValue != this.activeSection
          this.activeSection = newValue
        } else {
          this.activeSection = 'info'
        }
      }
    }
  },
  methods: {
    changeSection(section) {
      if (section != this.activeSection) {
        this.activeSection = section
        this.$router.replace({ name: this.$route.name, query: { section } })
      }
    }
  },
}
</script>

<style lang="scss">
.ghost-top-profile-img {
  h2 {
    font-size: 30px!important;
    font-weight: 300!important;
    color: #fff;
    padding: 20px 0;
    background: transparent!important;
    width: 75%;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
