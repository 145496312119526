<template>
  <b-card>
    <h5>Owned Companies ({{ suggestions.length }})</h5>
    <div
      v-for="(data, index) in suggestions"
      :key="data.avatar"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <b-link
        :to="{ name: 'company-info', params: { slug: data.slug } }"
        class="d-flex"
      >
        <b-avatar
          :src="data.logo"
          :text="avatarText(data.name)"
          class="mr-50"
          size="40"
        />
        <div class="user-page-info">
          <h6 class="mb-0">
            {{ data.name }}
          </h6>
          <small class="text-muted">{{ data.slug }}</small>
        </div>
      </b-link>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    suggestions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
