<template>
  <b-card
    class="m-0 p-0"
  >

    <!-- RENDER CATEGORIES -->
    <template v-if="type == 'categories'">
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click.prevent="getData('lists', data.item.id)"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- Column: ListsCount -->
        <template #cell(listsCount)="data">
          <b-badge
            pill
            variant="primary"
            class="text-capitalize"
          >
            {{ data.item.listsCount }}
          </b-badge>
        </template>

        <!-- Column: Created_at -->
        <template #cell(created_at)="data">
          <span class="text-center">{{ data.item.date }}</span>
          <br>
          <span class="text-center">{{ data.item.time }}</span>
        </template>

        <!-- TABLE LOADING DATA -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <p class="mt-1">
              <strong>Loading...</strong>
            </p>
          </div>
        </template>

      </b-table>
    </template>

    <!-- RENDER LISTS -->
    <template v-if="type == 'lists'">
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="lists"
        responsive
        :busy="isLoading"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.title.replace('@', ''))"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click.prevent="getData('contacts', data.item.id)"
            >
              {{ data.item.title }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Count -->
        <template #cell(count)="data">
          <b-badge
            pill
            variant="success"
            class="text-capitalize"
          >
            {{ data.item.count }}
          </b-badge>
        </template>

        <!-- Column: SMSCount -->
        <template #cell(SMSCount)="data">
          <b-badge
            pill
            variant="info"
            class="text-capitalize"
          >
            {{ data.item.SMSCount }}
          </b-badge>
        </template>

        <!-- Column: emailCount -->
        <template #cell(emailCount)="data">
          <b-badge
            pill
            variant="info"
            class="text-capitalize"
          >
            {{ data.item.emailCount }}
          </b-badge>
        </template>

        <!-- Column: SMSOptOut -->
        <template #cell(SMSOptOut)="data">
          <b-badge
            pill
            variant="danger"
            class="text-capitalize"
          >
            {{ data.item.SMSOptOut }}
          </b-badge>
        </template>

        <!-- Column: emailOptOut -->
        <template #cell(emailOptOut)="data">
          <b-badge
            pill
            variant="danger"
            class="text-capitalize"
          >
            {{ data.item.emailOptOut }}
          </b-badge>
        </template>

        <!-- Column: Created_at -->
        <template #cell(created_at)="data">
          <span class="text-center">{{ data.item.date }}</span>
          <br>
          <span class="text-center">{{ data.item.time }}</span>
        </template>

        <!-- TABLE LOADING DATA -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <p class="mt-1">
              <strong>Loading...</strong>
            </p>
          </div>
        </template>

      </b-table>
    </template>

    <!-- RENDER LISTS -->
    <template v-if="type == 'contacts'">
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="contacts"
        responsive
        :busy="isLoading"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`https://api.crmzz.com/${data.item.image}`"
                :text="avatarText(data.item.name)"
              />
            </template>
            <span>{{ data.item.name }}</span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <b-button
            v-if="data.item.email"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.v-success
            v-clipboard:copy="data.item.email"
            v-clipboard:success="onCopy"
            variant="gradient-success"
            class="btn-icon rounded-circle"
            :title="data.item.email"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          <b-button
            v-if="data.item.phone"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.v-info
            v-clipboard:copy="data.item.phone"
            v-clipboard:success="onCopy"
            variant="gradient-info"
            class="btn-icon rounded-circle"
            :title="data.item.phone"
          >
            <feather-icon icon="PhoneCallIcon" />
          </b-button>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Created_at -->
        <template #cell(created_at)="data">
          <span class="text-center">{{ data.item.date }}</span>
          <br>
          <span class="text-center">{{ data.item.time }}</span>
        </template>

        <!-- TABLE LOADING DATA -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <p class="mt-1">
              <strong>Loading...</strong>
            </p>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="paginationCurrentPage"
              :total-rows="pagination.totalItems || 0"
              :per-page="mainPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="paginate"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>

  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BAvatar, BCard, BCardText, BLink, BRow, BCol, BAvatarGroup, VBTooltip,
  BTable, BBadge, BMedia, BButton, BPagination, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BTable,
    BBadge,
    BMedia,
    BButton,
    BSpinner,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
        type: String,
        default: ''
    },
  },
  data() {
      return {
          isLoading: false,
          tableColumns: []
      }
  },
  watch: {
      type: {
          immediate: true,
          deep: true,
          handler(newValue) {
              if (newValue) {
                  if (newValue == 'categories') {
                        this.tableColumns = [
                            { key: 'name', label: 'List Name', sortable: true },
                            { key: 'listsCount', label: 'List(s) Count', sortable: true },
                            { key: 'created_at', label: 'Created', sortable: true },
                        ]
                  } else if (newValue == 'lists') {
                      this.isLoading = true

                        this.tableColumns = [
                            { key: 'title', label: 'List Name', sortable: true },
                            // { key: 'created_at', label: 'Created', sortable: true },
                            { key: 'count', label: 'Total', sortable: true },
                            { key: 'SMSCount', label: '#SMS', sortable: true },
                            { key: 'emailCount', label: '#Email', sortable: true },
                            { key: 'SMSOptOut', label: '#S.Opt', sortable: true },
                            { key: 'emailOptOut', label: '#E.Opt', sortable: true },
                        ]

                      this.getContactLists(this.$route.query.id)
                  } else if (newValue == 'contacts') {
                      this.isLoading = true

                      this.tableColumns = [
                            { key: 'name', label: 'Name', sortable: true },
                            // { key: 'created_at', label: 'Created', sortable: true },
                            { key: 'phone', label: 'Phone', sortable: true },
                            { key: 'email', label: 'Email', sortable: true },
                            // { key: 'emailCount', label: '#Email', sortable: true },
                            // { key: 'SMSOptOut', label: '#S.Opt', sortable: true },
                            // { key: 'emailOptOut', label: '#E.Opt', sortable: true },
                        ]

                      this.getContacts(this.$route.query.id)
                  }
              }
          }
      }
  },
  computed: {
      ...mapGetters('founders', ['lists', 'contacts', 'pagination']),
  },
  methods: {
      ...mapActions('founders', ['founderContactListsAction', 'founderContactsAction']),
    kFormatter,
    getData(type, id) {
        this.$router.replace({
            name: this.$route.name,
            params: this.$route.params,
            query: {
                section: type,
                id
            }
        })
    },
    paginate(page) {
        this.isLoading = true
        this.paginationCurrentPage = page

        if (this.type == 'contacts') {
            this.founderContactsAction({
                username: this.$route.params.username,
                id: this.$route.query.id,
                page
            }).then(() => {
                this.isLoading = false
            })
        }
    },
    getContactLists(id) {
        this.founderContactListsAction({ username: this.$route.params.username, id }).then(() => {
            this.isLoading = false
        })
    },
    getContacts(id) {
        this.founderContactsAction({ username: this.$route.params.username, id }).then(() => {
            this.isLoading = false
        })
    }
  },
}
</script>

<style lang="scss">
.p-0 {
    .card-body {
        padding: 0!important;
    }
}
</style>