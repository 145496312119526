<template>
  <b-card>
    <!-- about -->
    <div
      v-for="(data, key, index) in aboutData"
      :key="key"
      :class="index ? 'mt-2':''"
    >
      <template v-if="['email', 'phone', 'joined'].includes(key) && data">
        <h5 class="text-capitalize mb-75">
          {{ key }}
        </h5>
        <b-card-text>
          {{ data }}
        </b-card-text>
      </template>
    </div>

    <b-button
      v-if="aboutData.links && aboutData.links.crmzz"
      class="btn-icon mt-1 is-crmzz rounded-circle"
      @click="openExternalLink(aboutData.links.crmzz)"
    >
      <feather-icon icon="ExternalLinkIcon" />
    </b-button>

    <b-button
      v-if="aboutData.links && aboutData.links.facebook"
      class="btn-icon mt-1 is-facebook rounded-circle ml-1"
      @click="openExternalLink(aboutData.links.facebook)"
    >
      <feather-icon icon="FacebookIcon" />
    </b-button>

    <b-button
      v-if="aboutData.links && aboutData.links.twitter"
      class="btn-icon mt-1 is-twitter rounded-circle ml-1"
      @click="openExternalLink(aboutData.links.twitter)"
    >
      <feather-icon icon="TwitterIcon" />
    </b-button>

    <b-button
      v-if="aboutData.links && aboutData.links.instagram"
      class="btn-icon mt-1 is-instagram rounded-circle ml-1"
      @click="openExternalLink(aboutData.links.instagram)"
    >
      <feather-icon icon="InstagramIcon" />
    </b-button>

    <b-button
      v-if="aboutData.links && aboutData.links.linkedin"
      class="btn-icon mt-1 is-linkedin rounded-circle ml-1"
      @click="openExternalLink(aboutData.links.linkedin)"
    >
      <feather-icon icon="LinkedinIcon" />
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
