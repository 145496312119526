<template>
  <div
    v-if="profileData"
    id="user-profile"
  >
    <profile-header :header-data="profileData" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about :about-data="profileData" />
          <profile-suggested-pages :pages-data="profileData.reviewedCompanies" />
          <!-- <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <profile-post
            v-if="visibleSection == 'info'"
            :posts="profileData.reviews"
          />
          <profile-contacts
            v-else-if="visibleSection == 'categories'"
            :type="visibleSection"
            :items="profileData.contactsCategories"
          />
          <profile-contacts
            v-else
            :type="visibleSection"
          />
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        >
          <!-- <profile-latest-photos :latest-images="profileData.latestPhotos" /> -->
          <profile-suggestion :suggestions="profileData.ownedCompanies" />
          <!-- <profile-polls :polls-data="profileData.polls" /> -->
        </b-col>
        <!--/ latest photos suggestion and polls -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
/* eslint-disable */
import { BRow, BCol } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileContacts from './ProfileContacts.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom,
    ProfileContacts,
  },
  props: ['username'],
  data() {
    return {
      profileData: {},
      visibleSection: 'info',
    }
  },
  computed: {
    ...mapGetters('founders', ['founder']),
    ...mapGetters('customers', ['customer']),
  },
  watch: {
    '$route.query.section': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue != this.visibleSection) {
          this.visibleSection = newValue
        } else {
          this.visibleSection = 'info'
        }
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('founders', ['getFounderInfoAction']),
    ...mapActions('customers', ['getcustomerInfoAction']),
    getData() {
      if (this.$route.name === 'founder-info') {
        this.getFounderInfoAction(this.username).then(() => {
          this.profileData = this.founder
        })
      } else if (this.$route.name === 'customer-info') {
        this.getcustomerInfoAction(this.username).then(() => {
          this.profileData = this.customer
        })
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
