<template>
  <b-card>
    <h5 class="mb-2">
      Reviewed Companies ({{ pagesData.length }})
    </h5>

    <!-- suggested pages -->
    <div
      v-for="(data,index) in pagesData"
      :key="index"
      class="d-flex justify-content-start align-items-center mt-1"
    >
      <b-link
        :to="{ name: 'company-info', params: { slug: data.slug } }"
        class="d-flex"
      >
        <div class="mr-1">
          <b-avatar
            size="40"
            :src="data.logo"
          />
        </div>
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.name }}
          </h6>
          <small class="text-muted">{{ data.slug }}</small>
        </div>
      </b-link>
    </div>
    <!--/ suggested pages -->
  </b-card>
</template>

<script>
import { BCard, BAvatar, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
  },
  props: {
    pagesData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
